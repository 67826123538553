function Mirror({ path, alt, small }) {
  return (
    <>
      <img
        className="m-auto origin-bottom-left translate-x-[0px] pointer-events-none select-none"
        src={path}
        alt={alt}
      />
      <img
        className={`m-auto absolute left-0 right-0 -scale-y-100 opacity-20 [mask-image:linear-gradient(to_top,rgb(0,0,0),rgba(0,0,0,0)_15px)] lg:[mask-image:linear-gradient(to_top,rgb(0,0,0),rgba(0,0,0,0)_${
          small ? 15 : 30
        }px)]`}
        src={path}
        alt={alt}
      />
    </>
  );
}

export default Mirror;
