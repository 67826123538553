const Career = () => {
  return (
    <div className="md:container xl:w-[1200px] max-w-full mx-auto px-5 md:px-10 3xl:px-5">
      <div className="text-gray-600 leading-7 tracking-wide pb-16">
        <h3 className="font-bold mt-4 mb-10 uppercase text-center text-xl">
          Career Highlights:{" "}
        </h3>
        <p className="p-5 xs:p-10 border rounded-xl shadow-lg mb-10">
          <strong>- Freelancing:</strong> I started my career as a Freelancer on
          Fiverr in 2015 and worked until 2018. I collaborated with more then
          180 satisfied clients all over the world, providing solutions across
          various platforms. I specialized in building Web Pages, developing
          Complete Websites, and working with CMS platforms like Wix,
          Squarespace and WordPress. My expertise includes making unique and
          Custom WordPress Themes and Plugins, as well as increadsing site
          performance, bug fixing and resolving issues across different
          platforms.
        </p>
        <p className="p-5 xs:p-10 border rounded-xl shadow-lg">
          <strong>- Full-Time Role:</strong> In 2018 I started working remotely
          at CAMG (Consumer Attorney Marketing Group) based in Los Angeles and
          worked for 6.5 years. Here I contributed to a dynamic team environment
          focused on delivering unique solutions. On frontend, I developed
          Landing Pages from scratch, ready templates, WordPress and Unbounce.
          Worked with digital team and gained in depth experience in A/B testing
          and successful campaigns. On backend, I developed sales and event
          portals, had the opportunity to worked with the best programmers and
          contributed to core systems which was built on PHP/Laravel. I had an
          in depth experience in working with tremendous size MySQL database.
          And got proficient using dev tools like Docker, Git and DBeaver.
        </p>
      </div>
    </div>
  );
};

export default Career;
