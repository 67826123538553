import { useState } from "react";
import Loading from "../Loading";
import Mirror from "../Mirror";
import validator from "validator";
import classNames from "classnames";

function Mobile({ project, isolate, url }) {
  const [loading, setLoading] = useState(true);
  const testURL = url ?? "";
  const isValidURL = validator.isURL(testURL);
  const projectSrc = project ? project.src : "";
  const src = isValidURL ? testURL : projectSrc;
  const title = project ? project.title : "Testing";

  return (
    <div
      className={classNames({
        "absolute -bottom-24 xl:-bottom-10 left-60 w-[150px] pb-12": !isolate,
        "relative w-[280px] 3xs:w-[300px] mx-auto pb-12": isolate,
      })}
    >
      <Mirror path="img/device/mobile.png" alt="Mobile" />
      <div
        className={classNames(
          "absolute left-0 right-0 bottom-[90px] m-auto flex justify-center w-[88%] overflow-hidden bg-white",
          {
            "top-[10%] h-[70%]": !isolate,
            "top-[4.1%] md:top-[4%] h-[75%]": isolate,
          }
        )}
      >
        <iframe
          src={src}
          width="414"
          height="766"
          title={title}
          className={classNames(
            "absolute top-0 left-0 right-0 m-auto origin-top-left",
            {
              "scale-[0.332]": !isolate,
              "scale-[0.64] 3xs:scale-[0.638] md:scale-[0.665]": isolate,
            }
          )}
          onLoad={() => setLoading(false)}
        ></iframe>
        {loading && <Loading />}
      </div>
    </div>
  );
}

export default Mobile;
