import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import classNames from "classnames";
import useFetchData from "./hooks/useFetchData";
import useWindowSize from "./hooks/useWindowSize";

const Portfolio = () => {
  const { windowWidth } = useWindowSize();
  const ITEMS_PER_PAGE = windowWidth < 768 ? 2 : windowWidth < 992 ? 4 : 6;
  const [selectedTags, setSelectedTags] = useState(new Set(["All"]));
  const [activeType, setActiveType] = useState("frontend");
  const [openTags, setOpenTags] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const {
    data: projectsData,
    loading,
    error,
  } = useFetchData("/data/projects.json");

  if (loading) {
    return <div className="fetch-loading">Loading...</div>;
  }

  if (error) {
    return <div className="fetch-error">Error: {error}</div>;
  }

  const handleProjectType = (type) => {
    setActiveType(type);
    setSelectedTags(new Set(["All"]));
  };

  const handleTagChange = (tagName) => {
    setSelectedTags((prevSelectedTags) => {
      const newSelectedTags = new Set(prevSelectedTags);
      if (newSelectedTags.has(tagName)) {
        newSelectedTags.delete(tagName);
        if (newSelectedTags.size === 0) newSelectedTags.add("All");
      } else {
        newSelectedTags.add(tagName);
        newSelectedTags.delete("All");
      }
      return newSelectedTags;
    });
  };

  const filteredProjects = selectedTags.has("All")
    ? projectsData[activeType]
    : projectsData[activeType].filter((project) =>
        project.tags.some((tag) => selectedTags.has(tag))
      );

  const startIndex = currentPage * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentProjects = filteredProjects.slice(startIndex, endIndex);

  const handlePageChange = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <div className="container mx-auto px-4 flex gap-10 pb-10 max-xl:flex-col-reverse">
      <main className="xl:w-4/5 select-none">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-2">
          {currentProjects.map((project) => (
            <PortfolioProject
              key={project.id}
              project={project}
              type={activeType}
            />
          ))}
        </div>
        {filteredProjects.length > ITEMS_PER_PAGE && (
          <ReactPaginate
            previousLabel={"🡠"}
            nextLabel={"🡢"}
            breakLabel={"..."}
            pageCount={Math.ceil(filteredProjects.length / ITEMS_PER_PAGE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        )}
        {currentProjects.length < 1 && (
          <h3 className="font-bold text-xl text-gray-400/50">
            Will be added soon...
          </h3>
        )}
      </main>

      <aside className="xl:w-1/5 pt-5">
        <div className="rounded-lg overflow-hidden shadow-[0px_0px_5px_rgb(0_0_0/20%)]">
          <h2 className="font-bold text-xl pt-4 pb-3 px-4 border-b bg-[#475569] text-white">
            Projects
          </h2>
          <div className="flex justify-between text-center select-none py-3 px-4">
            {["frontend", "isolate", "fullstack"].map((type) => (
              <div
                key={type}
                className={classNames(
                  "text-center w-1/2 py-1 rounded-md cursor-pointer",
                  {
                    "bg-[#b0e0e6]": activeType === type,
                  }
                )}
                onClick={() => handleProjectType(type)}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </div>
            ))}
          </div>
          <div className="relative max-xl:px-4 overflow-hidden select-none">
            <input
              type="text"
              className="xl:hidden w-full border py-2 rounded-md pl-4 pr-7 mb-4 focus:outline-orange-400"
              value={Array.from(selectedTags).join(", ")}
              onClick={() => setOpenTags(true)}
              readOnly
            />
            <span
              className={classNames(
                "xl:hidden absolute right-7 top-2 font-medium text-orange-400",
                {
                  hidden: !openTags,
                }
              )}
              onClick={() => setOpenTags(false)}
            >
              X
            </span>
            <div
              className={classNames(
                "[&>label]:block [&>label]:cursor-pointer [&>label]:py-2 [&>label]:px-4 duration-500 max-h-screen",
                {
                  " max-xl:mb-0 max-xl:max-h-0": !openTags,
                  "max-xl:mb-4": openTags,
                }
              )}
            >
              <label className="bg-gray-100 select-none">
                <input
                  type="checkbox"
                  checked={selectedTags.has("All")}
                  onChange={(e) => setSelectedTags(new Set(["All"]))}
                />{" "}
                All
              </label>
              {Array.from(
                new Set(
                  projectsData[activeType].flatMap((project) => project.tags)
                )
              ).map((tag, index) => (
                <label
                  key={tag}
                  className="even:bg-gray-50 odd:bg-gray-100 select-none"
                >
                  <input
                    type="checkbox"
                    checked={selectedTags.has(tag)}
                    onChange={() => handleTagChange(tag)}
                  />{" "}
                  {tag}
                </label>
              ))}
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

const PortfolioProject = ({ project, type }) => {
  return (
    <div className="relative group border border-white hover:border-gray-100 duration-300 overflow-hidden hover:shadow-[1px_2px_10px_rgb(0_0_0/10%)] p-5 pb-6">
      <Link to={`preview/${type}/${project.id}`}>
        <img src={project.cover} alt={project.name} />
        <div className="absolute w-full bottom-0 left-0 overflow-hidden">
          <h4 className="xl:opacity-0 group-hover:opacity-80 duration-300 flex justify-between text-md font-medium px-5 py-3">
            {project.name}
            <span>
              <img
                className="w-6 h-6"
                src="img/icon/multi-device-black.png"
                alt="MultiDevice"
              />
            </span>
          </h4>
        </div>
      </Link>
    </div>
  );
};

export default Portfolio;
