import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ScrollTo from "./components/ScrollTo";
import Desktop from "./components/Preview/Desktop";
import Laptop from "./components/Preview/Laptop";
import Tablet from "./components/Preview/Tablet";
import Mobile from "./components/Preview/Mobile";
import classNames from "classnames";
import validator from "validator";
import useWindowSize from "./hooks/useWindowSize";
import useFetchData from "./hooks/useFetchData";

const Preview = () => {
  const { type, id } = useParams();
  const projectId = parseInt(id, 10);
  const [deviceSelected, setDeviceSelected] = useState(false);
  const [device, setDevice] = useState("All");
  const isolate = device !== "All";
  const devices = [
    { name: "Desktop", src: "img/icon/desktop.png", alt: "Desktop" },
    { name: "Laptop", src: "img/icon/laptop.png", alt: "Laptop" },
    { name: "Tablet", src: "img/icon/tablet.png", alt: "Tablet" },
    { name: "Mobile", src: "img/icon/mobile.png", alt: "Mobile" },
  ];
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    if (windowWidth < 1024 && device === "All") {
      setDevice("Desktop");
    } else if (windowWidth >= 1024 && !deviceSelected) {
      setDevice("All");
    }
    // eslint-disable-next-line
  }, [windowWidth]);

  const selectDevice = (device) => {
    setDevice(device);
    setDeviceSelected(true);
  };

  /*
  const [rotate, setRotate] = useState(false);
  const toggleRotate = () => {
    setRotate((rotate) => {
      return rotate ? false : true;
    });
  };
  */

  const {
    data: projectData,
    loading,
    error,
  } = useFetchData("/data/projects.json");

  if (loading) {
    return <div className="fetch-loading">Loading...</div>;
  }

  if (error) {
    return <div className="fetch-error">Error: {error}</div>;
  }

  const projectList = projectData[type];
  const project = projectList.find((p) => p.id === projectId);

  return (
    <>
      <ScrollTo target="nav" />
      <div
        className={classNames("md:container mx-auto px-4 overflow-hidden", {
          "pb-28 xl:pb-12": device === "All",
        })}
      >
        <div className="flex flex-col xs:flex-row items-center justify-center gap-2 3xs:gap-5 xs:gap-10 font-bold mb-10 select-none">
          <div className="flex items-center gap-4">
            Devices:
            <span className="inline-flex items-center h-8 2xs:h-10 [&>img]:max-h-full [&>img]:cursor-pointer [&>img.active]:border-b-2 [&>img.active]:border-red-600 gap-3 2xs:gap-4">
              {devices.map((deviceData) => (
                <img
                  key={deviceData.name}
                  className={classNames({ active: device === deviceData.name })}
                  onClick={() => selectDevice(deviceData.name)}
                  src={deviceData.src}
                  alt={deviceData.alt}
                  aria-label={deviceData.name}
                />
              ))}
            </span>
          </div>
          {/* {device === "Tablet" && (
            <div className="flex items-center gap-2 select-none">
              Rotate:
              <img
                className="h-8 2xs:h-10 cursor-pointer"
                src="img/icon/rotate.png"
                alt="rotate"
                onClick={() => toggleRotate()}
              />
            </div>
          )} */}
        </div>
        <div className="relative select-none text-center">
          {device === "All" || device === "Desktop" ? (
            <Desktop project={project} isolate={isolate} />
          ) : null}
          {device === "All" || device === "Laptop" ? (
            <Laptop project={project} isolate={isolate} />
          ) : null}
          {device === "All" || device === "Tablet" ? (
            <Tablet project={project} isolate={isolate} />
          ) : null}
          {device === "All" || device === "Mobile" ? (
            <Mobile project={project} isolate={isolate} />
          ) : null}
        </div>
      </div>
      <ProjectLink project={project} />
    </>
  );
};

export default Preview;

function ProjectLink({ project }) {
  const projectSrc = project ? project.src : "";
  const isValidURL = validator.isURL(projectSrc);

  return (
    <>
      {isValidURL && (
        <div className="container mx-auto px-4 h-10 max-md:-mb-2">
          <a
            className="flex gap-1 items-center justify-center leading-none max-w-full"
            href={project.src}
            target="_blank"
            rel="noreferrer"
          >
            <span className="inline-block break-all underline underline-offset-2">
              {project.src}
            </span>
            <img
              className="h-8 flex-shrink-0"
              src="img/icon/open-link.png"
              alt="open link"
            />
          </a>
        </div>
      )}
    </>
  );
}
