import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Hello from "./Hello";
import About from "./About";
import Testimonials from "./Testimonials";
import Certificates from "./Certificates";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Preview from "./Preview";
import Nav from "./components/Nav";
import Career from "./Career";
import Portfolio from "./Portfolio";
import TestPreview from "./TestPreview";

function App() {
  return (
    <BrowserRouter>
      <div className="relative 3xl:w-[1400px] max-w-full min-h-dvh m-auto">
        <LoadHeader />
        <Routes>
          <Route exact path="/" element={<Hello />} />
          <Route path="/about" element={<About />} />
          <Route path="/career" element={<Career />} />
          <Route path="/projects" element={<Portfolio />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/certificates" element={<Certificates />} />
          <Route path="/projects/preview/:type/:id" element={<Preview />} />
          <Route path="/preview/" element={<TestPreview />} />
          <Route path="/up" element={<About />} />
          <Route path="/up/about" element={<About />} />
          <Route path="/up/career" element={<Career />} />
          <Route path="/up/projects" element={<Portfolio />} />
          <Route path="/up/testimonials" element={<Testimonials />} />
          <Route path="/up/certificates" element={<Certificates />} />
          <Route path="/up/projects/preview/:type/:id" element={<Preview />} />
          <Route path="/up/preview/" element={<TestPreview />} />
        </Routes>
        <LoadFooter />
      </div>
    </BrowserRouter>
  );
}

function LoadHeader() {
  const location = useLocation();
  const path = location.pathname;
  const up = path.match("up") ?? false;
  return (
    <>
      {path !== "/" && (
        <>
          <Header up={up} />
          <Nav up={up} />
        </>
      )}
    </>
  );
}

function LoadFooter() {
  const location = useLocation();
  return location.pathname !== "/" && <Footer />;
}

export default App;
