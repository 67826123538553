import { useState } from "react";
import Loading from "../Loading";
import Mirror from "../Mirror";
import validator from "validator";
import classNames from "classnames";

function Tablet({ project, isolate, url }) {
  const [loading, setLoading] = useState(true);
  const testURL = url ?? "";
  const isValidURL = validator.isURL(testURL);
  const projectSrc = project ? project.src : "";
  const src = isValidURL ? testURL : projectSrc;
  const title = project ? project.title : "Testing";

  return (
    <div
      className={classNames({
        "absolute w-[280px] left-3 xl:left-0 -bottom-20 xl:bottom-0 pb-12 z-0":
          !isolate,
        "relative w-[190px] 3xs:w-[200px] 2xs:w-[240px] xs:w-[320px] md:w-[360px] lg:w-[440px] mx-auto pb-16":
          isolate,
      })}
    >
      <Mirror path="img/device/tablet.png" alt="Tablet" small={true} />
      <div
        className={classNames(
          "absolute right-0 bottom-[82px] m-auto flex justify-center overflow-hidden bg-white rounded-lg",
          {
            "top-[7.5%] h-[83.42%] w-[92%] -left-px": !isolate,
            "top-[5.2%] 2xs:top-[4.2%] xs:top-[3.6%] md:top-[3%] lg:top-[2.5%] h-[76%] 3xs:h-[77%] 2xs:h-[79%] xs:h-[82.5%] md:h-[83.2%] lg:h-[85%] w-[93%] left-0 xs:-left-1":
              isolate,
          }
        )}
      >
        <iframe
          src={src}
          width="768"
          height="1120"
          title={title}
          className={classNames(
            "absolute top-0 left-0 right-0 m-auto origin-top-left",
            {
              "scale-[0.344]": !isolate,
              "scale-[0.23] 3xs:scale-[0.242] 2xs:scale-[0.291] xs:scale-[0.389] md:scale-[0.435] lg:scale-[0.546]":
                isolate,
            }
          )}
          onLoad={() => setLoading(false)}
        ></iframe>
        {loading && <Loading />}
      </div>
    </div>
  );
}

export default Tablet;
