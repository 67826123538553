import { useState } from "react";
import Loading from "../Loading";
import validator from "validator";
import classNames from "classnames";

function Laptop({ project, isolate, url }) {
  const [loading, setLoading] = useState(true);
  const testURL = url ?? "";
  const isValidURL = validator.isURL(testURL);
  const projectSrc = project ? project.src : "";
  const src = isValidURL ? testURL : projectSrc;
  const title = project ? project.title : "Testing";

  return (
    <div
      className={classNames({
        "absolute w-[620px] xl:w-[700px] -right-5 -bottom-20 xl:bottom-0":
          !isolate,
        "relative w-[280px] 3xs:w-[320px] 2xs:w-[380px] xs:w-[500px] sm:w-[600px] md:w-[700px] lg:w-[800px] xl:w-[900px] mx-auto mb-9 md:mb-5":
          isolate,
      })}
    >
      <img
        className="m-auto pointer-events-none select-none"
        src="img/device/laptop.png"
        alt="Laptop"
      />
      <div className="absolute top-[8.8%] -left-px 2xs:left-0 right-0 m-auto flex justify-center w-[72.8572%] h-[76.191%] bg-white">
        <iframe
          src={src}
          width="1020"
          height="642"
          title={title}
          className={classNames(
            "absolute top-0 left-0 right-0 m-auto origin-top-left",
            {
              "scale-[0.445] xl:scale-50": !isolate,
              "scale-[0.2] 3xs:scale-[0.23] 2xs:scale-[0.272] xs:scale-[0.357] sm:scale-[0.43] md:scale-[0.504] lg:scale-[0.575] xl:scale-[0.645]":
                isolate,
            }
          )}
          onLoad={() => setLoading(false)}
        ></iframe>
        {loading && <Loading />}
      </div>
    </div>
  );
}

export default Laptop;
