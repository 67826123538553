import { useEffect, useState } from "react";
import ScrollTo from "./components/ScrollTo";
import Desktop from "./components/Preview/Desktop";
import Laptop from "./components/Preview/Laptop";
import Tablet from "./components/Preview/Tablet";
import Mobile from "./components/Preview/Mobile";
import classNames from "classnames";

const Preview = () => {
  const [displayRotate, setDisplayRotate] = useState(false);
  const [device, setDevice] = useState("All");
  const isolate = device !== "All";
  const devices = [
    { name: "Desktop", src: "img/icon/desktop.png", alt: "Desktop" },
    { name: "Laptop", src: "img/icon/laptop.png", alt: "Laptop" },
    { name: "Tablet", src: "img/icon/tablet.png", alt: "Tablet" },
    { name: "Mobile", src: "img/icon/mobile.png", alt: "Mobile" },
  ];
  const [url, setURL] = useState();

  useEffect(() => {
    setDisplayRotate(device === "Tablet");
    setURL(window.location.origin + "/about");
  }, [device]);

  return (
    <>
      <ScrollTo target="nav" />
      <div className="container mx-auto px-4 pb-12 overflow-hidden">
        <div className="text-center mb-10">
          <input
            className="focus:outline-none border w-[500px] max-w-full py-2 px-4 rounded-md shadow-md font-bold"
            type="text"
            placeholder="Enter URL here"
            value={url}
          />
        </div>
        <div className="flex items-center justify-center gap-10 font-bold mb-10 select-none">
          <div className="flex items-center gap-4">
            Devices:
            <span className="inline-flex items-center h-10 [&>img]:max-h-full [&>img]:cursor-pointer [&>img.active]:border-b-2 [&>img.active]:border-red-600 gap-4">
              {devices.map((deviceData) => (
                <img
                  key={deviceData.name}
                  className={classNames({ active: device === deviceData.name })}
                  onClick={() => setDevice(deviceData.name)}
                  src={deviceData.src}
                  alt={deviceData.alt}
                  aria-label={deviceData.name}
                />
              ))}
            </span>
          </div>
          <Rotate displayRotate={displayRotate} />
        </div>
        <div className="relative select-none">
          {device === "All" || device === "Desktop" ? (
            <Desktop project={null} isolate={isolate} url={url} />
          ) : null}
          {device === "All" || device === "Laptop" ? (
            <Laptop project={null} isolate={isolate} url={url} />
          ) : null}
          {device === "All" || device === "Tablet" ? (
            <Tablet project={null} isolate={isolate} url={url} />
          ) : null}
          {device === "All" || device === "Mobile" ? (
            <Mobile project={null} isolate={isolate} url={url} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Preview;

function Rotate({ displayRotate }) {
  if (displayRotate) {
    return (
      <div className="flex items-center gap-2">
        Rotate:
        <img
          className="h-10 cursor-pointer"
          src="img/icon/rotate.png"
          alt="rotate"
        />
      </div>
    );
  }
  return null;
}
